import $ from 'jquery';
import Foundation from 'foundation-sites';
import ActionCable from '@rails/actioncable';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import saveAs from 'file-saver';

window.$ = $;
window.Foundation = Foundation;
window.ActionCable = ActionCable;
window.Rails = Rails;
window.Turbolinks = Turbolinks;
window.saveAs = saveAs;
